<template>
  <div class="spc">
    <template v-for="block in content">
      <div v-if="block.type == 'textBlock'"
           :key="block.id"
           :class="{
             spc__text: true,
             'spc__text--border': block.border,
             'spc__text--bg': block.backgroundColor || block.pageBackgroundColor,
           }"
           :style="{
             backgroundColor: block.backgroundColor ? block.backgroundColor : null,
           }"
           v-html="block.text"></div>
      <NewsletterForm v-if="block.type == 'newsletterSignup'"
                      :key="block.id"
                      :block="block" />
      <blockquote v-if="block.type == 'quoteBlock'"
                  class="content__quote spc__quote"
                  :key="block.id">
        <p>{{ block.text }}</p>
        <small>{{ block.author }}</small>
      </blockquote>
      <figure v-if="block.type == 'imageBlock' && block.image"
              class="content__image"
              :key="block.id">
        <img :src="block.image.url"
             :width="block.image.width"
             :height="block.image.height" />
        <figcaption v-html="block.caption"></figcaption>
      </figure>
      <div v-if="block.type == 'videoBlock'"
           :key="block.id"
           class="content__video">
        <EpisodeInfoVideoPlayer :block="block" />
        <span class="caption"
              v-if="block.videoCaption">
          {{ block.videoCaption }}
        </span>
      </div>
      <ImageSlider v-if="block.type == 'imageSlider'"
                   :key="block.id"
                   :block="block" />
      <div v-if="block.type == 'captivateFm'"
           :key="block.id"
           class="content__captivate">
        <iframe style="width: 100%; height: 200px"
                frameborder="no"
                scrolling="no"
                seamless
                :src="`https://player.captivate.fm/episode/${block.episodeId}`"></iframe>
      </div>
    </template>
  </div>
</template>

<script>
import EpisodeInfoVideoPlayer from '@/components/EpisodeInfoVideoPlayer';
import ImageSlider from '@/components/ImageSlider';
import NewsletterForm from '@/components/NewsletterForm';

export default {
  name: 'SubpageContent',
  props: ['content'],
  components: {
    EpisodeInfoVideoPlayer,
    ImageSlider,
    NewsletterForm,
  },
  computed: {},
  mounted() { },
  methods: {},
};
</script>

<style lang="scss">
.spc {

  h2,
  h3,
  h4 {
    font-weight: bold;
    margin-top: 1rem;
    // margin-bottom: .25rem;
    line-height: 1.2;

    &:first-child {
      margin-top: 0;
    }
  }

  h4 {
    margin-bottom: 1rem;
    margin-top: 0;
  }

  p+p,
  p+h3,
  p+ul,
  ul+ul,
  h2+ul,
  ul+p {
    margin-top: 1rem;
  }

  a {
    text-decoration: underline;
    // border-bottom: 1px solid black;
  }

  p.lead {
    font-size: 1.3rem;
    line-height: 1.15;
  }
  p.lead + ol {
    margin-top: 1rem;
  }

  strong {
    font-weight: bold;
    line-height: 1.2;
  }

  li {
    line-height: 1.3;
    position: relative;
    padding-left: 1.5rem;

    &::before {
      content: '';
      background-image: url("data:image/svg+xml,%3Csvg width='27' height='17' xmlns='http://www.w3.org/2000/svg'%3E%3Cg stroke='%231D1D1B' stroke-width='.8' fill='none' fill-rule='evenodd'%3E%3Cpath d='M18.57 15.943l7.33-7.33-7.33-7.33M1 8.612h24.668'/%3E%3C/g%3E%3C/svg%3E");
      background-size: 100%;
      background-position: 0 60%;
      background-repeat: no-repeat;
      width: 1.2rem;
      height: 1.05rem;
      position: absolute;
      top: 0;
      left: 0;
    }

    &:first-child {
      margin-top: 0;
    }
  }

  &__text {
    margin: 2rem 0;
    padding: 0 0.75rem;
    box-sizing: border-box;

    @include bp-xs() {
      padding: 0 0.5rem;
    }

    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }

    .col--right & {
      padding-left: 0;

      @include bp-s() {
        padding-left: 0.75rem;
      }
    }

    &--border,
    &--bg {
      margin-left: 0.75rem;
      width: calc(100% - 0.75rem);
      padding: 0.75rem !important;

      .col--right & {
        margin-left: 0;
        margin-right: 0.75rem;

        @include bp-s() {
          margin-left: 0.75rem;
        }
      }

      @include bp-s() {
        margin-left: 0.75rem;
        width: calc(100% - 1.5rem);
      }
    }

    &--bg {
      background-color: var(--page-color);
    }

    &--border {
      border: 2px solid #000;
    }
  }

  &__newsletter {
    padding: 0.5rem;
    border: 2px solid #000;
    margin-left: 0.75rem;
    width: calc(100% - 0.75rem);
    box-sizing: border-box;

    .col--right & {
      margin-left: 0;
      margin-right: 0.75rem;

      @include bp-s() {
        margin-left: 0.75rem;
      }
    }

    @include bp-s() {
      margin-left: 0.75rem;
      width: calc(100% - 1.5rem);
    }

    &__headline:first-child {
      font-size: 0.8rem;
      margin: 0 0 1rem 0;
      font-weight: normal;
      text-transform: uppercase;
      letter-spacing: 0.05rem;
    }

    &__form {
      margin-top: 3rem;
      text-align: right;

      input {
        display: block;
        width: 100%;
        border: none;
        border-bottom: 2px solid #000;
        --webkit-appearance: none;
        border-radius: 0;
        font-size: 1rem;
        font-family: $f-main;
        margin: 0.5rem 0;
        line-height: 1.5;
        padding: 0;
        box-sizing: border-box;

        &:focus,
        &:active {
          background-color: #fff;
          outline: none;

          &::placeholder {
            color: #fff;
          }
        }

        &::placeholder {
          color: #000;
        }
      }

      button {
        --webkit-appearance: none;
        border-radius: 0;
        border: 2px solid #000;
        background: #fff;
        color: #000;
        font-family: $f-main;
        font-size: 1rem;
        padding: 0.25rem 1rem;
        margin-top: 2rem;
        cursor: pointer;
        transition: 0.2s box-shadow;
        box-shadow: 0 0 10px 0 rgba(#000, 0);

        &:hover {
          box-shadow: 0 0 10px 0 rgba(#000, 0.25);
        }
      }
    }
  }

  &__quote {
    max-width: 30rem;

    small {
      display: block;
      margin: 0 auto;
      width: 66.6%;
      max-width: 23rem;
    }
  }
}
</style>
